import React from 'react';
import GlobalProvider from './src/context';
import './src/styles/global.css';
import guid from "helpers/guid";

export const wrapRootElement = ({ element }) => {
    return <GlobalProvider>
        {element}
    </GlobalProvider>
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition, prevRouterProps }) => {
    window.history.scrollRestoration = 'manual'
    const currentPosition = getSavedScrollPosition(location) || [0, 0]
    const navigationEntries = performance.getEntriesByType('navigation');

    // Prevent scroll restoration when navigating back to the previous page with the same scroll position
    if ((location?.state?.preventScroll && prevRouterProps?.location?.state?.preventScroll) || 
        (navigationEntries?.[0]?.type === "navigate" && location?.state?.preventScroll && location?.pathname?.includes("/patio/") && prevRouterProps?.location?.pathname?.includes("/patio/")) ||
        (navigationEntries?.[0]?.type === "navigate" && location?.state?.preventScroll && location?.pathname?.includes("/grill-and-heating/") && prevRouterProps?.location?.pathname?.includes("/grill-and-heating/"))) {
        return false
    }
  
    setTimeout(() => {
        if (location.hash) {
            // If hash (`/#something`), smooth scroll to that position
            const item = document.querySelector(`${location.hash}`)
            const delayScroll = navigationEntries.length > 0 && (navigationEntries[0].type === 'reload' || navigationEntries[0].type === 'back_forward');
            setTimeout(() => { 
                item?.scrollIntoView({ behavior: 'smooth' }); 
            }, delayScroll ? 5 : 0);
        } else {
            // When using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
            window.scrollTo({
                top: currentPosition[1],
                left: currentPosition[0],
                behavior: 'instant',
            })
        }
    }, 0)
    return false
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
        // Push an event to the DataLayer with route change information
        window.dataLayer.push({
            event: 'page_view',
            event_id: `event_${guid()}`
        });
    }
    if (!prevLocation && !location.hash) {
        // Do not restore scroll position on page refresh without hash
        setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' }), 0)
    }
}