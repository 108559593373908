/* eslint-disable no-undef */
import guid from "helpers/guid";
import { CURRENCY } from "utils/constants";
import {getVersionFromQueryString} from "@root/utils/getFitsVersion";

const consentGranted = async () => new Promise((resolve) => {
    const checkCookieConsentApi = () => {
        if (window.CookieConsentApi && window.CookieConsentApi.getUserPreferences) {
            resolve(window.CookieConsentApi.getUserPreferences().accept_type === "all");
        } else {
            setTimeout(checkCookieConsentApi, 200);
        }
    };

    checkCookieConsentApi();
});

const eventId = () => `event_${guid()}`;

const getTotalPrice = (items) => Number(items.reduce((acc, item) => acc + item.quantity * item.price, 0).toFixed(2));

export const customerInfo = (customer) => {
    const { id: userId, firstName, lastName, email } = customer;
    return {
        userId, firstName, lastName, email,
    };
};

const trackPostscriptEvent = (event, data) => {
    if (typeof window === "undefined") return;
    const item = data?.items?.[0];

    if (item === undefined) return;

    const itemVariantId = item?.item_id?.split("/").pop();
    const itemId = item?.product_id?.split("/").pop() || itemVariantId;
    const itemPrice = item?.price;
    const itemCategory = [
        item.item_category,
        "-",
        item.item_category2,
        item.item_category3,
        item.item_category4,
        item.item_category5,
    ].filter(Boolean).join(" ");

    const postscripData = {
        // your Postscript Shop ID
        shop_id: "267984",
        // absolute URL of the current page
        url: window.location.href,
        // search_params as an object
        search_params: {},
        page_type: "product",
        // absolute URL of the referring page
        referrer: data?.prevUrl || document.referrer,
        resource: { // information about the product
            category: itemCategory,
            name: item.item_name,
            price_in_cents: itemPrice ? itemPrice * 100 : null,
            resource_id: itemId ? itemId * 1 : null,
            resource_type: "product",
            sku: null,
            variant_id: itemVariantId ? itemVariantId * 1 : null,
            vendor: item.item_brand,
        },
    };

    window?.postscript?.event(event, postscripData);
};

const trackEvent = (event, data) => {
    if (consentGranted()) {
        // console.log("track:", event, {
        //     event,
        //     event_id: eventId(),
        //     ...data,
        // });
        dataLayer.push({
            event,
            event_id: eventId(),
            ...data,
        });
    }
};

const trackEcomEvent = (event, ecommerce) => {
    if (consentGranted()) {
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        trackEvent(event, {
            ecommerce: {
                ...ecommerce,
                currency: CURRENCY,
            },
        });
    }
};

export const mapItems = (products, { item_list_name, item_list_id, coupon, extraParams } = {}) => products.map((product, index) => {
    // product.node comes from shopify cart, product is from pms
    // eslint-disable-next-line no-param-reassign
    product = product?.node ?? product;

    let itemMetrics = product?.item_metrics;
    if (itemMetrics === undefined) {
        // get item metrics from product?.customAttributes
        const customAttributes = product?.customAttributes;
        if (customAttributes) {
            itemMetrics = {
                item_category: customAttributes?.find((attr) => attr.key === "_item_category")?.value,
                item_category2: customAttributes?.find((attr) => attr.key === "_item_category2")?.value,
                item_category3: customAttributes?.find((attr) => attr.key === "_item_category3")?.value,
                item_category4: customAttributes?.find((attr) => attr.key === "_item_category4")?.value,
                item_category5: customAttributes?.find((attr) => attr.key === "_item_category5")?.value,
            };
        }
    }
    if (itemMetrics === undefined) {
        itemMetrics = {};
    }

    const variant = product?.currentVariant?.node ?? product?.variant ?? product?.variants?.[0]?.node ?? product?.variants?.edges?.[0]?.node;
    let fabricOption = variant?.selectedOptions?.find((option) => option?.name?.toLowerCase() === "fabric");
    const colorOption = variant?.selectedOptions?.find((option) => option?.name?.toLowerCase() === "color");
    const typeOption = variant?.selectedOptions?.find((option) => option?.name?.toLowerCase() === "type");
    const sizeOption = variant?.selectedOptions?.find((option) => option?.name?.toLowerCase() === "size");
    const pickRowOption = variant?.selectedOptions?.find((option) => option?.name?.toLowerCase() === "pick row");
    const pickComboOption = variant?.selectedOptions?.find((option) => option?.name?.toLowerCase() === "pick combo");
    const rowOption = variant?.selectedOptions?.find((option) => option?.name?.toLowerCase() === "row");

    // console.log("product", product);
    // console.log("variant", variant);

    if (fabricOption?.value === undefined) {
        if (product?.title?.toLowerCase().includes("supreme")) {
            fabricOption = { value: "Supreme" };
        } else if (product?.title?.toLowerCase().includes("proguard")) {
            fabricOption = { value: "ProGuard" };
        } else if (product?.title?.toLowerCase().includes("5 layer")) {
            fabricOption = { value: "5 Layer" };
        } else if (product?.title?.toLowerCase().includes("elite")) {
            fabricOption = { value: "Elite" };
        }
    }

    const customTotalPrice = product?.customAttributes?.find((attribute) => attribute.key === "totalPrice")?.value;

    let price = customTotalPrice || variant?.price?.amount || variant?.price;
    if (`${price}`.includes("10000")) {
        price = undefined;
    }

    const productId = product?.shopify_id ?? variant?.product?.id ?? product?.id;

    const categories = product?.categories?.map((category) => category?.name) || [];

    let qpcFits = getVersionFromQueryString();

    if(!itemMetrics?.item_category && categories.length > 0) {
        // if categories[0].lowercase includes any of : boat, car, motorsport
        let category = categories[0].toLowerCase();
        let ignoreTopCategory = ['boat', 'car', 'motorsport'];

        if(ignoreTopCategory.some(cat => category.includes(cat))) {
            itemMetrics.item_category = categories?.[1];

            if(qpcFits) {
                itemMetrics.item_category += ' (qpc)';
                itemMetrics.item_category2 = qpcFits;
            }
        } else {
            itemMetrics.item_category = categories?.[0];
            itemMetrics.item_category2 = categories?.[1];
            itemMetrics.item_category3 = categories?.[2];
            itemMetrics.item_category4 = categories?.[3];
            itemMetrics.item_category5 = categories?.[4];
        }

    }

    // if handle includes 'warranty', set item_category to Warranty.. if it doesn't already exist
    if (product?.handle?.toLowerCase().includes("warranty") && !itemMetrics?.item_category) {
        itemMetrics.item_category = "Warranty";
    }

    // if item_category = Tarps, set item_category3 to size if it exists
    if (itemMetrics?.item_category === "Tarps" && sizeOption?.value) {
        itemMetrics.item_category3 = sizeOption?.value;
    }

    // If item_category = Seat Covers, set item_category2 to type if it exists
    if (itemMetrics?.item_category === "Seat Covers" && typeOption?.value) {
        itemMetrics.item_category2 = typeOption?.value;
    }
    if(itemMetrics?.item_category === "Seat Covers" && rowOption?.value) {
        itemMetrics.item_category2 = rowOption?.value;
    }
    if (itemMetrics?.item_category === "Seat Covers" && pickRowOption?.value) {
        itemMetrics.item_category2 = pickRowOption?.value;
    }
    if (itemMetrics?.item_category === "Seat Covers" && pickComboOption?.value) {
        itemMetrics.item_category3 = pickComboOption?.value;
    }

    const item = {
        item_id: variant?.id,
        item_name: product?.title,
        affiliation: "Seal Skin Covers",
        currency: CURRENCY,
        index: product?.index ?? index,
        item_brand: "Seal Skin Covers",
        item_category: itemMetrics?.item_category,
        item_category2: itemMetrics?.item_category2,
        item_category3: itemMetrics?.item_category3,
        item_category4: itemMetrics?.item_category4,
        item_category5: itemMetrics?.item_category5,
        item_variant: [fabricOption?.value, colorOption?.value, typeOption?.value, rowOption?.value, pickRowOption?.value, pickComboOption?.value, sizeOption?.value].filter(Boolean).join(" / ") ?? variant?.title,
        price,
        quantity: product.quantity ?? 1,
        product_id: productId,
        product_id_clean: productId?.split("/").pop(),
        item_id_clean: variant?.id.split("/").pop(),
        vehicle_id: product?.vehicle_id,
        ...(item_list_name ? { item_list_name } : {}),
        ...(item_list_id ? { item_list_id } : {}),
        ...(coupon ? { coupon } : {}),
    };
    if (product?.discounts?.length) {
        let itemDiscount = 0;
        const coupons = [];

        product.discounts.forEach((discount) => {
            itemDiscount += discount.amount;
            coupons.push(discount.title);
        });
        item.discount = itemDiscount;
        item.coupon = coupons.join(",");
    }
    
    // console.log("debug", item);

    return item;
});

export const trackAddToCart = (products, { prevUrl }) => {
    const items = mapItems([].concat(products));
    trackEcomEvent("add_to_cart", {
        items,
        value: getTotalPrice(items),
    });

    trackPostscriptEvent("add_to_cart", { items: mapItems([].concat(products), { extraParams: true }), prevUrl });
};

export const trackRemoveFromCart = (products) => {
    const items = mapItems([].concat(products));
    trackEcomEvent("remove_from_cart", {
        items,
        value: getTotalPrice(items),
    });
};

export const trackQuantityChange = (products, quantity, data) => {
    if (quantity < 0) trackRemoveFromCart(products, data);
    if (quantity > 0) trackAddToCart(products, data);
};

export const trackViewItem = (product, { prevUrl }) => {
    trackEcomEvent("view_item", { items: mapItems([product]) });

    trackPostscriptEvent("page_view", { items: mapItems([product], { extraParams: true }), prevUrl });
};

export const trackSliderClick = (product) => {
    trackEcomEvent("slider_click", { items: mapItems([product]) });
};

export const trackBeginCheckout = (cart) => {
    const ecommerce = {
        value: cart.totalPrice,
        items: mapItems(cart.items, { coupon: cart?.discountCode }),
    };

    if (cart.discountCode) ecommerce.coupon = cart.discountCode;

    trackEcomEvent("begin_checkout", ecommerce);
};

export const trackLogIn = (customer) => trackEvent("login", { customer: customerInfo(customer) });

export const trackSignUp = (customer) => trackEvent("sign_up", { customer: customerInfo(customer) });

export const trackViewCart = (cart) => {
    const items = mapItems(cart.items, { coupon: cart.discountCode });
    trackEcomEvent("view_cart", {
        items,
        value: getTotalPrice(items),
        coupon: cart.discountCode,
    });
};

export const trackViewCollection = (products, item_list_name, item_list_id) => {
    const items = mapItems(products, { item_list_name, item_list_id });
    trackEcomEvent("view_item_list", {
        items, item_list_name, item_list_id,
    });
};

export const trackSelectItem = (product, item_list_name, item_list_id) => {
    const items = mapItems([product], { item_list_name, item_list_id });
    trackEcomEvent("select_item", {
        items, item_list_name, item_list_id,
    });
};
