import { NEW_RELIC_KEY } from "utils/constants";

async function sendLog(message, level) {
    if( typeof window === "undefined" ) return;

    const logUrl = `https://log-api.newrelic.com/log/v1?Api-Key=${NEW_RELIC_KEY}`;

    try {
        const response = await fetch(logUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify([
                {
                    message,
                    "entity.name": "Headless", 
                    environment: "production",
                    timestamp: new Date().toISOString(),
                    userInfo: getUserInfo(),
                    level,
                },
            ]),
        });

        if (!response.ok) {
            console.error("Failed to send log to New Relic:", response.statusText);
        }
    } catch (error) {
        console.error("Error sending log to New Relic:", error);
    }
}


function getUserInfo() {
    try {
        const userAgent = navigator.userAgent || "Unknown";
        const platform = navigator.platform || "Unknown";
        const language = navigator.language || "Unknown";
        const screenWidth = screen.width || 0;
        const screenHeight = screen.height || 0;

        return {
            os: platform,
            browser: getBrowser(userAgent),
            language: language,
            screenResolution: `${screenWidth}x${screenHeight}`,
            userAgent: userAgent,
        };
    } catch (error) {
        console.error("Error gathering user info:", error);
        return {
            os: "Unknown",
            browser: "Unknown",
            language: "Unknown",
            screenResolution: "Unknown",
            userAgent: "Unknown",
            error: error.message || "Unknown error",
        };
    }
}

function getBrowser(userAgent) {
    try {
        if (userAgent.includes("Chrome") && !userAgent.includes("Edg") && !userAgent.includes("OPR")) {
            return "Chrome";
        } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
            return "Safari";
        } else if (userAgent.includes("Firefox")) {
            return "Firefox";
        } else if (userAgent.includes("Edg")) {
            return "Edge";
        } else if (userAgent.includes("OPR") || userAgent.includes("Opera")) {
            return "Opera";
        } else if (userAgent.includes("Trident") || userAgent.includes("MSIE")) {
            return "Internet Explorer";
        } else {
            return "Unknown";
        }
    } catch (error) {
        console.error("Error determining browser:", error);
        return "Unknown";
    }
}

export default sendLog;
