export const getVersionFromQueryString = () => {
    if(typeof window === "undefined") return null;
    const query = window.location.search;

    if(query.length === 0) return null;

    // string to object
    const queryObj = query
        .slice(1)
        .split('&')
        .map(q => q.split('='))
        .reduce((a, [key, value]) => ({ ...a, [key]: value }), {});

    return getVersion(queryObj);
}

export const getVersion = (query) => {
    const queryKeysToArray = Object.keys(query);

    if (queryKeysToArray?.length >= 1) {
        const findVersion = queryKeysToArray.find((queryKey) => queryKey.includes("version"));
        if (!findVersion) return null;

        // In case the version is something like "version_test_2", we join back on "_"
        return findVersion.split("_").slice(1).join("_").replaceAll("-", " ");
    }

    return null;
};