/* eslint-disable camelcase */
import patioData from "components/patio-configurator/data";
import extractGid from "./extract-gid";

const getStableCheckout = async (checkout, inputRefs) => {
    const data = {
        items: checkout.items.map((item) => {
            const customAttributes = item?.node.customAttributes?.length > 0 ? item.node.customAttributes.reduce((array, value) => ({
                ...array,
                ...(value.key !== "handle" && { [value.key.charAt(0).toUpperCase() + value.key.slice(1)]: value.value }),
            }), {}) : {};

            const dynamicAttributes = item?.node.customAttributes.filter((attr) => attr.key.startsWith("c_") || attr.key === "_measurements")
                .reduce((obj, attr) => ({
                    ...obj,
                    [attr.key.replace("c_", "")]: attr.value,
                }), {});

            const analyticsAttributes = item?.node.customAttributes.filter((attr) => attr.key.startsWith("_item_category") || attr.key.startsWith("_item_variant"))
                .reduce((obj, attr) => ({
                    ...obj,
                    [attr.key]: attr.value,
                }), {});

            const patioInputs = item?.node?.customAttributes?.filter((node) => inputRefs?.find((inp) => inp?.key === node?.key)).map((attr) => ({ name: inputRefs?.find((inp) => inp.key === attr.key).name, value: attr.value }));
            const referenceImages = Object.fromEntries(
                Object.entries(customAttributes)
                    .filter(([key]) => key.startsWith("ReferenceImage"))
                    .map(([key, value], index) => [`Image${index === 0 ? "" : ` ${index + 1}`}`, value]),
            );

            return ({
                id: item.node.id,
                product_id: +extractGid(item.node.variant.product.id),
                variant_id: +extractGid(item.node.variant.id),
                quantity: +item.node.quantity,
                ...(item.node.customAttributes.filter((attr) => attr.key === "_customProduct")[0]
                    ? {
                        price: +item.node.variant.price.amount * 100,
                        properties: {
                            _customProduct: "Yes",
                            __productData: customAttributes.__productData,
                            __basePrice: customAttributes.__basePrice,
                            _productImage: customAttributes._productImage,
                            _squareFeet: customAttributes._squareFeet,
                            ...({
                                ...patioInputs.reduce((array, input) => ({
                                    ...array,
                                    [input.name]: input.value,
                                }), {}),
                            }),
                            ...dynamicAttributes,
                            ...referenceImages,
                            ...(customAttributes?.SpecialRequest && { "Special Request": customAttributes.SpecialRequest }),
                            ...analyticsAttributes,
                        },
                    }
                    : { price: +item.node.variant.price.amount, properties: customAttributes ?? {} }),
            });
        }),
        discountCode: checkout.discountCode,
    };
    return data;
};

export const buildStableSyncCart = async (cart) => {
    if (!cart?.items || cart?.items?.length === 0) return ({ items: [], discountCode: null });
    const data = {
        items: cart.items.map((item) => {
            const customAttributes = item.node.customAttributes.reduce((arr, curr) => ({ ...arr, [curr.key]: curr.value }), {});
            const product_id = +extractGid(item.node.variant.product.id);
            const variant_id = +extractGid(item.node.variant.id);
            return ({
                variant_id,
                product_id,
                properties: customAttributes,
                quantity: item.node.quantity,
                price: +item.node.variant.price.amount * 100,
            });
        }),
    };
    return { ...data, discountCode: cart.discountCode };
};

export default getStableCheckout;
