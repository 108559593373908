import React, { createContext, useState } from "react";

const initialState = {};

export const ProductFinderContext = createContext(null);

const useProductFinder = () => {
    const [productFinder, setProductFinder] = useState(initialState);
    const [errorField, _setErrorField] = useState(null);
    const [errorFieldRefresher, setErrorFieldRefresher] = useState(0);


    function setErrorField(errorField) {
        _setErrorField(errorField);
        setErrorFieldRefresher((prev) => prev + 1);
    }

    return { productFinder, setProductFinder, errorField, setErrorField, errorFieldRefresher };
};

const ProductFinderProvider = ({ children }) => {
    const productFinder = useProductFinder();
    return (
        <ProductFinderContext.Provider value={productFinder}>
            {children}
        </ProductFinderContext.Provider>
    );
};

export default ProductFinderProvider;
